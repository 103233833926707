import moment from "moment"
import "moment/locale/sv"
import React from "react"
import { graphql } from "gatsby"
import MenuBar from "../../components/MenuBar/menuBar"
import EventGroups from "../../components/EventGroups/eventGroups"
import Promotions from "../../components/Promotions/promotions"
import Footer from "../../components/Footer/footer"
import Info from "../../components/Info/info"
import BaseLayout from "../../components/Layout/layout"

import "./sport-template.scss"

const _ = require("lodash")

const SportTemplate = ({ data, location, pageContext }) => {
  //console.log("Sport template", data)
  const sports = data.sports.edges
  const channels = data.channels.edges
  const selectedSport = data.sport
  const selectedDay =
    location.pathname === data.sport.uri
      ? ""
      : _.replace(location.pathname, `${selectedSport.uri}/`, "")
  const sportEventEdges =
    data.events && data.events.edges ? data.events.edges : []

  const availableDays = _.uniqWith(
    _.map(sportEventEdges, edge =>
      moment(edge.node.date).locale("sv").format("YYYYMMDD")
    ),
    _.isEqual
  )
  return (
    <BaseLayout>
      {data.promotedEvents && (
        <Promotions promotedEvents={data.promotedEvents.edges} />
      )}

      <MenuBar
        sports={sports}
        selectedSport={selectedSport}
        channels={channels}
        availableDays={availableDays}
        selectedDay={selectedDay}
      />
      <Info
        header={`All ${selectedSport.name} på TV & stream`}
        description={`Vill du planera din vecka efter all ${selectedSport.name} som spelas? Här hittar du
          all ${selectedSport.name} som sänds via TV, stream eller både och. Klicka på loggorna för mer
          information om respektive sändning och hur du får tag på matchen. Du kan även klicka
          på matcherna för att få information om matchodds, eventuella speltips och förhandsanalyser.`}
      />
      <div className="sport-content-container">
        <EventGroups eventEdges={sportEventEdges} selectedDay={selectedDay} />
      </div>
      <Footer links={pageContext.allLinks} />
    </BaseLayout>
  )
}

export default SportTemplate

export const pageQuery = graphql`
  query sportPageQuery($id: String!) {
    sport: contentfulSport(contentful_id: { eq: $id }) {
      name
      code
      backgroundColor
      uri
      id
    }
    channels: allContentfulChannel(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          id
          name
          uri
        }
      }
    }
    sports: allContentfulSport(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          name
          uri
          code
          id
        }
      }
    }
    promotedEvents: allContentfulEvent(
      filter: { promote: { eq: true }, node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            logoUri
            logoUriNeg
          }
          group {
            name
            logoUri
            logoUriNeg
          }
          sport {
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
        }
      }
    }
    events: allContentfulEvent(
      filter: { sportId: { eq: $id }, node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            uri
          }
          sport {
            code
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
          channelLinks {
            channel
            externalUrl
          }
          channels {
            id
            name
            externalUrl
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
