import React from 'react';
import './info.scss';

const Info = ({ header, description }) => (
  <div className="info">
    <div className="info-container">
      <h2 className="info-container-header">{header}</h2>
      <p className="info-container-description">{description}</p>
    </div>
  </div>);

export default Info;
